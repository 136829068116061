import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [9,[2],[3]],
		"/admin": [26,[6],[7]],
		"/admin/analytics": [27,[6],[7]],
		"/admin/analytics/events": [29,[6],[7]],
		"/admin/analytics/[startDate]/[endDate]/[[category]]": [28,[6],[7]],
		"/admin/event": [30,[6],[7]],
		"/admin/event/new": [34,[6],[7]],
		"/admin/event/[event]": [31,[6],[7]],
		"/admin/event/[event]/new-ticket": [32,[6],[7]],
		"/admin/event/[event]/setup/[[tab]]": [33,[6],[7]],
		"/admin/invoices": [35,[6],[7]],
		"/admin/invoices/[invoice]": [36,[6],[7]],
		"/admin/membership": [37,[6],[7]],
		"/admin/membership/new": [42,[6],[7]],
		"/admin/membership/subscription/[sub]": [43,[6],[7]],
		"/admin/membership/[membership]": [38,[6],[7]],
		"/admin/membership/[membership]/invite": [39,[6],[7]],
		"/admin/membership/[membership]/invite/new": [40,[6],[7]],
		"/admin/membership/[membership]/setup/[[tab]]": [41,[6],[7]],
		"/admin/orders": [44,[6],[7]],
		"/admin/orders/[order]": [45,[6],[7]],
		"/admin/pricing/[pricing]": [46,[6],[7]],
		"/admin/settings": [47,[6,8],[7]],
		"/admin/settings/billing": [48,[6,8],[7]],
		"/admin/settings/profile": [49,[6,8],[7]],
		"/admin/ticket/[ticket]": [50,[6],[7]],
		"/admin/ticket/[ticket]/setup": [51,[6],[7]],
		"/(app)/calendar": [10,[2],[3]],
		"/(app)/checkout": [11,[2],[3]],
		"/(app)/dbg": [12,[2],[3]],
		"/(app)/dbg/throw-500": [13,[2],[3]],
		"/(app)/e": [14,[2,4],[3]],
		"/(app)/e/[event]": [15,[2,4],[3]],
		"/(app)/for-organisers": [16,[2],[3]],
		"/(app)/my/orders": [18,[2,5],[3]],
		"/(app)/my/orders/[order]": [19,[2,5],[3]],
		"/(app)/my/subscriptions": [20,[2,5],[3]],
		"/(app)/my/subscriptions/[sub]": [21,[2,5],[3]],
		"/(app)/m/[membership]/[[invite]]": [17,[2],[3]],
		"/(app)/o/[organiser]/tos": [23,[2],[3]],
		"/(app)/o/[organiser]/[[category]]": [22,[2],[3]],
		"/(app)/privacy": [24,[2],[3]],
		"/(app)/tos": [25,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';